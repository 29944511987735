<!-- Fixed navbar -->
<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <div class="container">
    <span class="navbar-brand">{{ title }}</span>
  </div>
</nav>

<div class="container main-container">
  <router-outlet></router-outlet>
</div> <!-- /container -->

<!-- If you don't need this ribbon, you can also uninstall the github-fork-ribbon-css npm package -->
<!-- The color for this ribbon is overridden in styles.scss -->
<a class="github-fork-ribbon right-top d-none d-md-block" href="https://github.com/kbarnes3/base-angular-app" data-ribbon="Fork me on GitHub" title="Fork me on GitHub">Fork me on GitHub</a>
<footer class="footer" role="contentinfo">
  <div class="container">
    <p>&copy; 2020-2023 BaseAngularAppAuthors </p>
    <p>Built from version {{ gitVersion }}</p>
  </div>
</footer>
